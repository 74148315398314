.tile {
    border: #4d4dff;
    border-width: 3px;
    border-style: solid;
}

.tile:hover {
    cursor:pointer;
}

.tile-header {
    background-color: #4d4dff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    font-size: large;
    font-weight: bold;
}

.tile-body {
    background-color: #d3deff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    font-size: small;
}

.tile-icon {
    margin-right: 15px;
}
