@import "./node_modules/bootstrap/scss/_functions"; 
@import "./node_modules/bootstrap/scss/_variables";

$theme-colors: (
  primary: #0081C9,
  light: #D2E9F1
);

//$body-bg: #D2E9F1;

//$primary: #3d1255;

//.bg-primary {
//  background-color: #3d1255!important;
//}

//$list-group-bg: theme-color("dark");
//$list-group-color: $white;
//$list-group-active-border-color: theme-color("dark");
//$list-group-border-width: 0;

//$dropdown-link-active-bg: $white;
//$dropdown-link-active-color: $black;

//$primary: theme-color("dark");

@import "~bootstrap/scss/bootstrap.scss";

a {
  color: var(--primary);
} 

.page-link {
  color: var(--primary);
}

.page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
}
