@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');

.modal-dialog {
    max-width: 600px;
    max-height: 400px;
    height: 100%;
}

.modal-content {
    /* height: 100%; */
    min-height: 400px;
    background-color: var(--tr-secondary-color);
    border-radius: 10px;
    border-width: 2px;
    border-color: white;
}

.modal-content .modal-header {
    background-color: var(--tr-secondary-color) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-title {
    color: white;
    font-size: 1.8rem;
    padding: 1rem;
}

.modal-header .close {
    color: white;
    opacity: 1;
    padding: 2rem;
}

.modal-content .modal-body {
    color: white;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0rem;
}

.modal-body .col {
    font-size: 1.2rem;
}

.modal-body .tarot-modal-p {
    margin-bottom: 0.4rem;
}

.modal-body .tarot-modal-p-sub {
    margin-bottom: 0rem;
}

.modal-body .tarot-modal-p-error {
    margin-bottom: 0rem;
    font-weight: 800;
    color: tomato;
}

.modal-registration-link {
    cursor: pointer;
    color: var(--tr-light-color);
    font-weight: 600;
}

.modal-body .tarot-modal-input {
    background-color: var(--tr-light-color);
    width: 100%;
    border-width: 0px;
    border-radius: 3px;
}

.modal-body .tarot-modal-select {
    background-color: var(--tr-light-color);
    width: 100%;
    font-size: 1rem;
    padding: 7px;
    border-width: 0px;
    border-radius: 3px;
}

h1 {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: xx-large;
    color: #0081C9;
    margin-top: 0px;
    text-align: left;
}

h1:after { 
    content:none; 
}

button.close {
    font-size:large;
}

.modal-header {
    border-bottom-style: none;
    height: 10px;
}

.modal-footer {
    border-top-style: none;
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.modal-footer p {
    color: white;
}

.modal-footer .btn {
    border-color: white;
    border-width: 2px;
    width: 100%;
}

.modal-footer .btn:hover {
    border-color: white;
    border-width: 2px;
    width: 100%;
}

.input-group {
    margin-top: 20px;
}

.form-check, .form-file {
    margin-top: 20px;
}

.input-group-text {
    background-color: unset ;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: unset;
    border-bottom: none;
}

.custom-file-upload {
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.custom-file-label {
    background-color: unset ;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: unset;
    border-bottom: none;
    margin-right: 30px;
}

.custom-file-label::after {
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.modal-image {
    width: 340px;
}

@media (max-width: 1199.98px) {
    .modal-image {
        width: 310px;
    }
}

@media (max-width: 991.98px) {
    .modal-image {
        width: 270px;
    }
    
    h1 {
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: x-large;
        color: #0081C9;
        margin-top: 0px;
        text-align: left;
    }

    h1:after { 
        content:none; 
    }

}

@media (max-width: 767.98px) {
    .modal-image {
        display: none;
    }
}
