#sidebar {
    position: relative;
    top: 80px;
/*    width: 300px;*/
/*    height: 100%;*/
    min-height: 100vh;
    display: flex;
}

/*#sidebar .pro-sidebar {
    margin-top: 50px;
    height: calc(100vh-50px);
}*/

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: unset;
}

#sidebar .closemenu {
    color: var(--tr-light-color);
    z-index: 9999;
    line-height: 20px;
    font-weight: bold;
    font-size: 22px;
/*    margin-top: 15px;
    margin-bottom: 15px;*/
    margin-left: 265px;
    cursor: pointer;
}

#sidebar .closemenu-collapse {
    color: var(--tr-light-color);
    z-index: 9999;
    line-height: 20px;
    font-weight: bold;
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 45px;
    cursor: pointer;
}

#sidebar .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#sidebar .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

#sidebar .pro-sidebar-inner {
    background-color: var(--tr-primary-color);
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color:#D4E8EA;
    font-weight: bold;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: var(--tr-light-color);
    margin: 10px 0px;
    font-weight: bold;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: var(--tr-light-color);
    color: #000;
    border-radius: 3px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
}

.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: var(--tr-light-color) !important;
}

.pro-sidebar .pro-menu a:hover {
    text-decoration: none;
    color: white !important;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, var(--tr-secondary-color) 0%, var(--tr-secondary-color) 100%);
}

#sidebar .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: var(--tr-primary-color);   
}

#sidebar .logo {
    padding: 20px;
}
  
@media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
}

#sidebar .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    background-color: var(--tr-primary-color);  
}

#tarot-search {
    display: flex;
}

#tarot-search .search-menu {
    margin-left: 32px;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
}

#tarot-search .search-field {
    margin-right: 30px;
    margin-left: 0px;
    float: right;
}

#tarot-search .search-menu-collapsed {
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    float: left;
}

#tarot-search .search-menu-popper {
    visibility: hidden;
    position: fixed;
    left: 80px;
    margin-top: -20px;
    z-index: 10;
    background-color: var(--tr-primary-color);
    transition: left 2s, transform 2s;
}

#tarot-search .search-menu-popper .search-typeahead {
    width: 240px;
    padding: 20px;
}

#tarot-search:hover .search-menu-popper {
    visibility: visible;
}

#tarot-search .search-menu-popper:hover {
    visibility: visible;
}

#sidebar .pro-arrow {
    color: var(--tr-light-color);
    border-color: unset;
}

#tarot-demo {
    background-color: var(--tr-light-color);
    color: var(--tr-primary-color);
    width: 240px;
    min-height: 20px;
    margin: 30px;
    padding: 10px;
}

.tarot-menu-item-big {
    display: list-item;
}

.tarot-menu-item-small {
    display: none;
}

#userpic-small-mobile {
    display: none;
}

@media screen and (max-width: 720px) {
    #sidebar .expanded {
        width: unset;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        z-index: 999;
    }   

    #tarot-brand {
        display: none;
    }

    #sidebar  {
        width: 0px;
        position: fixed;
        transition: width, left, right, 0.3s;
        margin-top: 70px;
        display: block;
        z-index: 999;
        height: 100%;
        top: 0px;
    }   

    #sidebar.menu-expanded {
        width: 300px;
        margin-top: 70px;
        top: 0px;
    }

    .pro-sidebar-header {
        display: none;
    }

    .pro-sidebar-content > #tarot-search {
        display: none;
    }

    .tarot-burger-menu svg {
        width: 30px;
        height: 30px;
        color: white;
        margin-top: 6px;
        position: absolute;
        margin-left: 5px;
    }

    .tarot-menu-item-big {
        display: none;
    }

    .tarot-menu-item-small {
        display: list-item;
    }

    #userpic {
        display: none;
    }

    #userpic-small-mobile {
        display: flex;
        justify-content: center;
    }

    #userpic-small-mobile .p-avatar {
        display: flex;
        color: white;
        background-color: var(--tr-secondary-color);
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    #userpic-small-mobile .p-avatar > img {
        width: 100%;
        height: auto;
        border-color: white;
        border-style: solid;
        border-radius: 100px;
    }

    #userpic-small-mobile .p-avatar-lg {
        font-size: 2.4rem;
    }

    .tarot-content-expanded {
        position: absolute;
    }
}